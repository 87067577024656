.terms-conditions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.error-message {
    word-wrap: break-word;
}

.register-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;
    flex-direction: column;
    justify-self: center;
}

.terms-conditions-link {
    color: blue;
    text-decoration: underline;
    text-decoration-color: blue;
}

.terms-conditions-link:hover {
    cursor: pointer;
}