.strategy-section {
    flex: 1;
    border: 2px solid #8a2be2;
    margin: 10px;
    height: 100vh;
}

.strategy-heading {
    height: 80px;
    font-size: 25px;
    display: flex;
    align-items: center;
    padding-left: 100px;
    font-weight: bold;
}

.add-button{
    height: 20px;
    width: 30px;
    padding-left: 60px;
    padding-bottom: 30px;
    padding-right: 10px;
    align-self: center;
    
}

.add-button:hover{
    cursor: pointer;
}

.material-symbols-outlined{
    font-size: 35px;

}

.strategy-row-list{
    display: flex;
   justify-content: space-between;
   padding-left: 100px;

}

/* .strategy-detail{
    padding: 0 60px;
} */

