.traders-approval-list {
    display: flex;
}

.buttons-list {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 300px;
    padding-left: 30%;
}
