.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto; 
    
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: auto;
    flex-direction: column;
    justify-self: center;
}

.button-group{
    display: flex;
    flex-direction: column;
}