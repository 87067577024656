.heading{
    display: flex;
}

.heading-1{
    flex: 0.3;
}

.heading-2{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    flex: 1;
    width: 50%;
    margin-right: 20px;
}

.heading-button-component{
    display: flex;
    justify-content: end;
}