.subscribe-modal-title{
    font-size: 20px;
    font-weight: bold !important;
}

.subscribe-strategy-label{
    padding: 10px 0;
    font-weight: 600;
}

