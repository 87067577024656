.strategy-modal-default{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.field-container{
    display: flex; 
    align-items: center;
    flex-direction: column;
}

.label-container{
    display: flex;
}

.field-multi-container{
    display: flex;
    flex: 1;
}